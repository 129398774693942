import axios from "./axios";
import getHeaders from "./headers";

const SignUpService = async ({ email }, password) => {
  let dataset = {
    username: email,
    email,
    password,
  };

  let response = await axios
    .post("/api/v1/signup", dataset, { headers: getHeaders() })
    .then((res) => {
      console.log(res);
      return res.data;
    })
    .catch((err) => {
      alert(err);
      return null;
    });

  return response;
};
export default SignUpService;
