import React,{useState} from 'react'
import { Link , useHistory } from 'react-router-dom'
import '../../App.css'
import resetPassword from '../../services/resetPassword';

export default function ResetPassword() {

    const [password,setPassword] = useState("");
    const [cpass, setCpass] = useState('');
    const history = useHistory();

    const ShowData= async(e)=>{
        e.preventDefault();
       let email = localStorage.getItem('email');
       if(cpass !== password){
            alert("both passwords doesn't match");
           window.location.reload();
       }else{
        history.push('/login');
        let {status,message} =  await resetPassword(email,password);
        if(status) alert(message);
        else alert(message);
       }        
    }
    return (
        <div className="text-center m-5-auto">
            <h2>Enter your new password</h2>
            <form action="/home">
                <p>
                    <label>Password</label>
                    <br/>
                    <input type="password" name="password" onChange={(e) => {setPassword(e.target.value)}} required />
                </p>
                <p>
                    <label>confirm Password</label>
                    <br/>
                    <input type="password" name="password" onChange={(e) => {setCpass(e.target.value)}} required />
                </p>
                <p>
                    <button id="sub_btn" type="submit" onClick={ShowData}>Reset</button>
                </p>
            </form>
           
        </div>
    )
}
