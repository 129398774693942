import axios from './axios'
import getHeaders from './headers'

const forgetPassword = async (email,password) => {
    

   let response = await axios.get(`/api/v1/forget_password/${email}`, {headers: getHeaders()}).then(res => {
        return res.data;    
    })
    .catch(err => {alert(err); return null;})

    return response;
}
export default forgetPassword