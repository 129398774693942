import axios from './axios'
import getHeaders from './headers'

const SignInService = async (email,password) => {
    
    let dataset = {   
        "email" : email,
        "password" : password,
    }   

   let response = await axios.post('/api/v1/signin', dataset, {headers: getHeaders()}).then(res => {
        return res.data;        
    })
    .catch(err => {alert(err); return null;})

    return response;
}
export default SignInService