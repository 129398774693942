import React, { useState } from 'react'
import { Link,useHistory } from 'react-router-dom'

import '../../App.css'
import forgetPassword from '../../services/forgetPassword';

export default function ForgetPasswordPage() {
   const history = useHistory();
    const [email,setEmail] = useState('');

    const submit  = async(e)=>{
        history.push('/');
        e.preventDefault();
        let {success,message} = await forgetPassword(email);
        localStorage.setItem('email',email);
        if(success){
            alert(message);
         }else{
            alert(message);
         }
    }

    return (
        <div className="text-center m-5-auto">
            <h2>Reset your password</h2>
            <h5>Enter your email address and we will send you instructions to reset password</h5>
            <form action="/login">
                <p>
                    <label id="reset_pass_lbl">Email address</label><br/>
                    <input type="email" name="email" required onChange={(e)=> setEmail(e.target.value)}/>
                </p>
                <p>
                    <button id="sub_btn" type="submit" onClick={submit}>Send password reset email</button>
                </p>
            </form>
            <footer>
                <p>First time? <Link to="/register">Create an account</Link>.</p>
                <p><Link to="/">Back to Homepage</Link>.</p>
            </footer>
        </div>
    )
}
