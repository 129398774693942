import axios from 'axios'

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  validateStatus: status => status < 500
})

axiosInstance.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.toJSON().message === 'Network Error') {
    window.alert('Please check your internet!')
  }
  return Promise.reject(error)
})

export default axiosInstance
