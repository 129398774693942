import React, { useState } from "react";
import { Link } from "react-router-dom";
import postDocument from "../../services/postDocument";

const initialAPIState = {
  status: null,
  success: null,
  message: null,
  error: {},
};

export default function HomePage() {
  let [file, setFile] = useState("");
  let [apiresp, setApiresp] = useState(initialAPIState);

  const onFileChange = (event) => {
    setApiresp(initialAPIState);
    setFile(event.target.files[0]);
  };

  const fileData = () => {
    if (file) {
      return <p>You have choosen {file.name}</p>;
    } else {
      return <p>Choose before Pressing the Upload button</p>;
    }
  };

  const submiForm = async (e) => {
    if (file) {
      e.preventDefault();
      setApiresp(initialAPIState);
      const resp = await postDocument(file);
      setApiresp(resp);
      console.log(initialAPIState, resp);
    } else alert("Please choose the .csv file first before uploading");
  };
  return (
    <div className="text-center">
      <Link to="/">
        <button className="primary-button">Log out</button>
      </Link>
      <br />
      <br />
      {/* <h1 className="main-title home-page-title">welcome to our app</h1> */}
      <form action="/home">
        <label>Upload the CSV file here</label>
        <br />
        <input
          type="file"
          style={{ width: "100%" }}
          accept=".csv"
          onChange={onFileChange}
        />
        <button
          id="sub_btn"
          type="submit"
          style={{ marginTop: "10px", marginBottom: "5px" }}
          onClick={submiForm}
        >
          Upload
        </button>
        {fileData()}
      </form>
      {apiresp.success == false && (
        <table width="80%" style={{ margin: "auto" }}>
          <thead>
            <tr>
              <th>Key</th>
              <th>Message</th>
              <th>Invalids</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(apiresp.error).map((k, i) => (
              <tr
                key={k}
                style={{ background: `${i % 2 == 0 ? "#ccc" : "#fff"}` }}
              >
                <td>{k}</td>
                <td>{apiresp.error[k].error}</td>
                <td>{(apiresp.error[k].errorvalues || []).join(", ")}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {apiresp.success == true && <p>Successfully uploaded.</p>}
    </div>
  );
}
