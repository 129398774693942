import axios from "./axios";
import getHeaders from "./headers";

const postDocument = async (file) => {
  const dataset = new FormData();
  dataset.append("docs", file, file.name);

  let response = await axios
    .post("/api/v1/upload", dataset, { headers: getHeaders() })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      alert(err);
      return null;
    });
  return response;
};
export default postDocument;
