import axios from './axios'
import getHeaders from './headers'

const resetPassword = async (email,password) => {

    let dataset = {
        'password' : password
    }
   let response = await axios.post(`/api/v1/reset_password/${email}`,dataset,
    {headers: getHeaders()}).then(res => {
        return res.data;    
    })
    .catch(err => {alert(err); return null;})

    return response;
}
export default resetPassword