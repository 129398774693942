import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "../../App.css";
import SignUpService from "../../services/signUpservices";

export default function SignUpPage() {
  const [inputs, setInputs] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();
  const [checked, setChecked] = useState(true);

  const GetData = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const submitData = async (e) => {
    e.preventDefault();
    if (checked) {
      let { token, success, message } = await SignUpService(inputs, password);
      if (success) {
        localStorage.setItem("token", token);
        history.push("/home");
      } else {
        alert(message);
      }
    } else {
      alert("please accept our terms and conditions");
    }
  };

  return (
    <div className="text-center m-5-auto">
      <h2>Join us</h2>
      <h5>Create your personal account</h5>
      <form action="/home">
        <p>
          <label>Email address</label>
          <br />
          <input type="email" name="email" onChange={GetData} required />
        </p>
        <p>
          <label>Password</label>
          <br />
          <input
            type="text"
            name="password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            required
          />
        </p>
        <p>
          <input
            type="checkbox"
            name="checkbox"
            id="checkbox"
            defaultChecked={checked}
            onChange={() => setChecked(!checked)}
            required
          />
          <span>
            I agree all statements in
            <a
              href="https://freshdatalabs.com/terms-conditions/"
              target="_blank"
              rel="noopener noreferrer"
            >
              terms of service
            </a>
          </span>
          .
        </p>
        <p>
          <button id="sub_btn" type="submit" onClick={submitData}>
            Register
          </button>
        </p>
      </form>
      <footer>
        <p>
          <Link to="/">Back to Homepage</Link>.
        </p>
      </footer>
    </div>
  );
}
