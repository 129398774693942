import React,{useState} from 'react'
import { Link , useHistory } from 'react-router-dom'
import '../../App.css'
import SignInService from '../../services/signInservices';

export default function SignInPage() {

    const [password,setPassword] = useState("");
    const [email,setEmail] = useState("");
    const history = useHistory();

    const ShowData= async(e)=>{
        e.preventDefault();
        
        let {accessToken, success,message} = await SignInService(email,password);
         if(success){
            localStorage.setItem("token", accessToken);
            history.push('/home');
         }else{
            alert(message);
         }
    }
    return (
        <div className="text-center m-5-auto">
            <h2>Sign in to us</h2>
            <form action="/home">
                <p>
                    <label>Username or email address</label><br/>
                    <input type="text" name="first_name" onChange={(e) => { setEmail(e.target.value) }} required />
                </p>
                <p>
                    <label>Password</label>
                    <Link to="/forget-password"><label className="right-label">Forget password?</label></Link>
                    <br/>
                    <input type="password" name="password" onChange={(e) => { setPassword(e.target.value) }} required />
                </p>
                <p>
                    <button id="sub_btn" type="submit" onClick={ShowData}>Login</button>
                </p>
            </form>
            <footer>
                <p>First time? <Link to="/register">Create an account</Link>.</p>
                <p><Link to="/">Back to Homepage</Link>.</p>
            </footer>
        </div>
    )
}
